import { css } from '@emotion/react';

const cookie = css`
  .cookieContentClass {
    text-align: center;
    br {
      display: none;
    }
    margin-bottom: 5px !important;
    @media screen and (min-width: 769px) {
      text-align: initial;
      max-width: 774px;
      margin-left: auto !important;
      br {
        display: initial;
      }
      margin-bottom: 15px !important;
    }
  }
  .cookieButtonClass {
    max-width: 226px;
    margin: 0 auto;
    button {
      font-size: 14px !important;
    }
    margin-bottom: 32px;
    @media screen and (min-width: 769px) {
      margin-right: auto;
      button {
        margin-right: 0 !important;
        font-size: 16px !important;
      }
      margin-bottom: initial !important;
    }
  }
  .CookieConsent {
    bottom: -1px !important;
    height: 182px !important;
    @media screen and (min-width: 769px) {
      height: 96px !important;
    }
  }
`;

export default cookie;
