import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { theme } from '../styles';
import Menu from '@components/Menu';
import { IPrismicIndex, MenuData, PrismicFooter } from '../../types/prismic';
import { NavigationThemeContext } from '../context/NavigationThemeContext';

const { breakpoints } = theme;

type Props = {};

const Navigation: React.FC<Props> = () => {
  const [showHeader, setShowHeader] = useState(true);
  const { data, prismicFooterData } = useStaticQuery(query);
  const navigationData: IPrismicIndex = data.edges[0].node.data;
  const { logo, logo_dark, body } = navigationData;

  const menuData: MenuData = { body };

  const footerData: PrismicFooter = prismicFooterData.edges[0].node.data;

  const { navigationTheme } = useContext(NavigationThemeContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hideHeader = urlParams.get('hideHeader');
    if (hideHeader === 'true') {
      setShowHeader(false);
    }
  }, []);

  return (
    <Container style={{ display: showHeader ? 'flex' : 'none' }}>
      <LogoWrapper>
        <Link to='/'>
          {navigationTheme === 'dark' ? (
            <Logo src={logo_dark?.url} alt={'Hyperhuman logo'} />
          ) : (
            <Logo src={logo?.url} alt={'Hyperhuman logo'} />
          )}
        </Link>
      </LogoWrapper>
      <Menu menuData={menuData} footerData={footerData} />
    </Container>
  );
};

export default Navigation;

const LogoWrapper = styled.div`
  z-index: 1;
`;

const Container = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  position: relative;
  z-index: 999;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: 0;
  }
`;

const Logo = styled.img`
  width: 150px;
`;

const query = graphql`
  query NavigationData {
    prismicFooterData: allPrismicIndex {
      ...FooterFieldsFragment
    }
    data: allPrismicIndex {
      edges {
        node {
          lang
          data {
            logo {
              url
              alt
            }
            logo_dark {
              url
              alt
            }
            body {
              ... on PrismicIndexDataBodyGroupOfLinks {
                items {
                  inner_link
                  inner_link_label
                  inner_link_id
                  hidden
                }
                primary {
                  link
                  link_label
                  link_is_external
                  link_is_button
                  link_id
                }
              }
            }
            copyright_text
          }
        }
      }
    }
    featuredWorkouts: allPrismicFeaturedWorkouts {
      edges {
        node {
          lang
          data {
            featured_workouts_enabled
          }
        }
      }
    }
  }
`;
