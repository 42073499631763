import styled from '@emotion/styled';
import { theme } from '@styles';

const { colors, breakpoints } = theme;

const HeaderSubtitle = styled.h2`
  font-size: 22px;
  line-height: 30px;
  color: ${colors.grannySmith};
  font-weight: 400;
  text-align: left;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0 auto;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.3px;
    padding: 0px;
    text-align: center;
  }
`;

export default HeaderSubtitle;
