import { logger } from '@utils';
import axios, { AxiosRequestConfig } from 'axios';

export const request = async (route: string, options?: AxiosRequestConfig) => {
  const url = `${route}`;
  const requestLog = `${options && options.method ? options.method : 'GET'} ${url}`;

  logger.info(`${requestLog}`);
  if (options) {
    logger.debug(`[Request options]: ${JSON.stringify(options, null, 2)}`);
  }

  const requestBody: AxiosRequestConfig = {
    ...options,
    method: options?.method ? options.method : 'GET',
    url: url,
  };

  const response = await axios(requestBody);

  if (response) {
    logger.success(`${requestLog}: `, response);
    return response;
  }
};
