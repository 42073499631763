import styled from '@emotion/styled';
import theme from '../theme';

const { colors, breakpoints } = theme;

const SectionSubtitle = styled.h4<{ color?: string; opacity?: number }>`
  margin: 0;
  color: ${({ color }) => color ?? colors.grannySmith};
  opacity: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin: 0px 40px 40px 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    opacity: ${({ opacity }) => opacity ?? 1};
    font-size: 20px;
    line-height: 26px;
    margin: 0px auto 78px auto;
    max-width: initial;
    text-align: center;
  }
`;

export default SectionSubtitle;
