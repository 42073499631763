import React, { useState, useEffect } from 'react';
import { Global, css, ThemeProvider } from '@emotion/react';
import '@reach/skip-nav/styles.css';
import Navigation from './Navigation';
import Cookies from '@components/Cookies';
import { pathsWithoutNavigation } from '@config/paths';

import { theme, reset, cookie, swiper } from '@styles';
// @ts-ignore
import { i18nConfig } from '@config/i18n';

import 'typeface-ibm-plex-sans';
import 'typeface-poppins';
import { PageProps } from 'gatsby';
import { PageContext } from '@appTypes';
import { NavigationThemeProvider } from '../context/NavigationThemeContext';
import { isBrowser } from '@utils';
import { Head } from './Head';

const LocaleContext = React.createContext({});

const Layout: React.FC<Omit<PageProps<{}, PageContext>, 'children'>> = ({ children, pageContext: { locale } }) => {
  const [showNavigation, showShowNavigation] = useState<boolean>(true);
  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const mobileApp = urlParams.get('mobileApp');
      showShowNavigation(!mobileApp && !pathsWithoutNavigation.includes(window.location.pathname));
    }
  }, []);

  return (
    <LocaleContext.Provider value={{ locale, i18n: i18nConfig }}>
      <Head />
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        <NavigationThemeProvider>
          <div>
            {showNavigation ? <Navigation /> : <></>}
            {children}
          </div>
        </NavigationThemeProvider>
      </ThemeProvider>
      <Cookies />
    </LocaleContext.Provider>
  );
};

const globalStyle = css`
  ${swiper}
  ${reset}
  ${cookie}
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ::selection {
    background-color: ${theme.colors.geyser};
  }
  .async-hide {
    opacity: 0 !important;
  }
`;

export { LocaleContext, Layout };
