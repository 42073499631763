import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={26} viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3 12.691h18M3 6.454h18M3 18.929h18'
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default SvgComponent;
