import { OUTER_SPACE } from '@constants';
import styled from '@emotion/styled';
import theme from '../theme';

const { breakpoints } = theme;

const SectionTitleH1 = styled.h1`
  color: ${OUTER_SPACE};
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.3px;
  text-align: left;
  font-family: 'Poppins';
  margin: 0px 24px 4px 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 40px;
    letter-spacing: -0.2px;
    margin-bottom: 12px;
    text-align: center;
  }
`;

export default SectionTitleH1;
