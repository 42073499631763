import styled from '@emotion/styled';
import { theme } from '@styles';

const { colors, breakpoints } = theme;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 700;
  font-family: 'Poppins';
  line-height: 44px;
  letter-spacing: -1px;
  color: ${colors.outerSpace};
  text-align: left;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 100px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 52px;
    line-height: 62px;
    letter-spacing: -1px;
    padding: 0px;
    text-align: center;
    margin: 60px auto 16px auto;
  }
  em {
    color: red;
  }
`;

export default HeaderTitle;
