export const pathMetadata = {
  '/404': { embeddable: true, withNavigation: true },
  '/about-us': { embeddable: false, withNavigation: true },
  '/blog': { embeddable: false, withNavigation: true },
  '/book-a-demo': { embeddable: false, withNavigation: true },
  '/book-success': { embeddable: false, withNavigation: true },
  '/campaign': { embeddable: false, withNavigation: true },
  '/club': { embeddable: false, withNavigation: true },
  '/content-api': { embeddable: false, withNavigation: true },
  '/exercises': { embeddable: true, withNavigation: false },
  '/exercise': { embeddable: true, withNavigation: false },
  '/featured-workouts': { embeddable: false, withNavigation: true },
  '/features': { embeddable: false, withNavigation: true },
  '/groups': { embeddable: true, withNavigation: false },
  '/group': { embeddable: true, withNavigation: false },
  '/index': { embeddable: false, withNavigation: true },
  '/legal': { embeddable: false, withNavigation: true },
  '/partners': { embeddable: false, withNavigation: true },
  '/plans': { embeddable: true, withNavigation: false },
  '/plan': { embeddable: true, withNavigation: false },
  '/pricing': { embeddable: false, withNavigation: true },
  '/privacy': { embeddable: false, withNavigation: true },
  '/scan': { embeddable: true, withNavigation: false },
  '/wix': { embeddable: false, withNavigation: true },
  '/workouts': { embeddable: true, withNavigation: false },
};

export const embeddablePaths: Array<string> = (Object.keys(pathMetadata) as Array<keyof typeof pathMetadata>).filter(
  (path) => pathMetadata[path].embeddable,
);

export const nonEmbeddablePaths: Array<string> = (Object.keys(pathMetadata) as Array<keyof typeof pathMetadata>).filter(
  (path) => !pathMetadata[path].embeddable,
);

export const pathsWithoutNavigation: Array<string> = (
  Object.keys(pathMetadata) as Array<keyof typeof pathMetadata>
).filter((path) => !pathMetadata[path].withNavigation);
