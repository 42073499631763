import styled from '@emotion/styled';
import { theme } from '@styles';

import { space, SpaceProps, layout, LayoutProps, compose, typography, TypographyProps } from 'styled-system';

const { colors } = theme;

type TextProps = SpaceProps &
  TypographyProps &
  LayoutProps & {
    flex?: number | string;
    textAlign?: string;
    color?: string;
    capitalize?: boolean;
    fontSize?: number;
    type?: string;
    underline?: boolean;
    debug?: boolean;
    fontFamily?: string;
    opacity?: number;
    uppercase?: boolean;
  };

const CustomText = styled.div<TextProps>`
  ${compose(space, layout, typography)}
  opacity: ${({ opacity }) => opacity};
  flex: ${({ flex }) => flex};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'initial')};
  text-align-vertical: center;
  text-decoration-line: ${({ underline }) => (underline ? 'underline' : 'none')};
  color: ${({ color }) => (color ? color : colors.outerSpace)};
`;

CustomText.defaultProps = {
  fontSize: 18,
  type: 'regular',
  capitalize: false,
  textAlign: 'left',
  flex: 'none',
  underline: false,
  opacity: 1,
};

export default CustomText;
