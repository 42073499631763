import { isBrowser, isFrame } from '@utils';
import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { embeddablePaths } from '@config/paths';

const schemaOrgJSONLD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  'mainEntityOfPage': ' https://bit.ly/2XNp316',
  'naics': ' 812990 ',
  'url': ' https://hyperhuman.cc/ ',
  'additionalType': [
    ' https://en.wikipedia.org/wiki/Exercise ',
    ' https://en.wikipedia.org/wiki/Physical_fitness ',
    ' https://en.wikipedia.org/wiki/Well-being ',
    ' https://en.wikipedia.org/wiki/Nutrition',
    ' https://en.wikipedia.org/wiki/Personal_trainer ',
  ],
  'name': 'Hyperhuman',
  'foundingDate': '2020-12-02',
  'description':
    ' Hyperhuman is a digital publishing and content monetization platform for the fitness industry. The product extracts key insights and metadata from workout videos and turns them into reusable content blocks using proprietary AI technology. This creates unique opportunities to provide highly customized fitness content that adapts to users in realtime based on their goals, biometrics, and training environment. Our mission is to offer the most streamlined path for creating, distributing, and monetizing quality video content and a world-class experience for fitness content consumption.',

  'sameAs': ['https://www.crunchbase.com/organization/hyperhuman', 'https://www.linkedin.com/company/hyperhuman/'],
  'founder': [
    {
      '@type': 'Person',
      'sameAs': ' https://davidteodorescu.com/ ',
      'description':
        ' Product Designer with 13 years of experience. Focused on product thinking, interaction design and visual design to create products that serve millions of users. I was featured on various tech publications like TechCrunch, Inc. Magazine and TNW, I write on Medium about product design and psychology, give talks from time to time and curate design stories on my Twitter account..',
      'url': ' https://www.crunchbase.com/person/david-teodorescu-6343 ',
      'name': 'David Teodorescu',
      '@id': ' https://www.linkedin.com/in/davidteodorescu/ ',
    },
    {
      '@type': 'Person',
      'sameAs': ' https://www.predusca.ro/ ',
      'description':
        'Experienced tech entrepreneur and product leader. Passionate about innovative products built around great teams and inspiring missions. MBA & Master of Computer Science graduate with 17+ years experience in the tech industry ranging from start-ups to enterprises and gone through several high profile mergers and acquisitions. Nurturing a growth and business-oriented mindset in diverse multicultural teams. My high goals and passion are my key motivation and everyday fuel. GT cars and sport enthusiast that is always up to great challenges and a healthy competition.',
      'url': ' https://www.crunchbase.com/person/bogdan-predusca ',
      'name': ' Bogdan Predusca',
      '@id': ' https://www.linkedin.com/in/bogdanpredusca/ ',
    },
    {
      '@type': 'Person',
      'sameAs': ' https://start-up.ro/startup-ul-hyperhuman-fondat-de-fosti-membri-fitbit-500-000-de-eur-investitie/ ',
      'description':
        'Dan Nica is a co-founder of Renovatio IT Solutions, a software creation studio for mobile and smart living. Nica holds a bachelor’s degree in business administration from the Academy of Economic Studies, Bucharest. Later on he completed his master’s degree in international business administration at Wiesbaden Business School, Germany.',
      'url': ' https://www.crunchbase.com/person/dan-nica',
      'name': 'Dan Nica',
      '@id': 'https://www.linkedin.com/in/danadriannica/ ',
    },
  ],
  'parentOrganization': {
    '@type': 'Organization',
    'contactPoint': 'https://www.insightpartners.com/contact/',
    'name': 'Insight Partners',
    '@id': 'https://www.insightpartners.com/',
  },
  'foundingLocation': {
    '@type': 'Place',
    'name': 'Bucharest, Romania',
    'sameAs': ' https://en.wikipedia.org/wiki/Bucharest',
    '@id': ' https://www.wikidata.org/wiki/Q19660',
  },
  'contactPoint': {
    '@type': 'ContactPoint',
    'url': 'contact@hyperhuman.cc',
    'name': 'Hyperhuman Email',
    '@id': 'contact@hyperhuman.cc',
  },
  'image': {
    '@type': 'ImageObject',
    'width': '300',
    'height': '54',
    'url': ' https://hyperhuman.cdn.prismic.io/hyperhuman/396d8bb7-5aaf-493c-a9ca-1a2a9eb6bba5_logo.svg',
    'name': 'Hyperhuman Logo',
    '@id': ' https://hyperhuman.cdn.prismic.io/hyperhuman/396d8bb7-5aaf-493c-a9ca-1a2a9eb6bba5_logo.svg',
  },
  'logo': {
    '@id': ' https://hyperhuman.cdn.prismic.io/hyperhuman/396d8bb7-5aaf-493c-a9ca-1a2a9eb6bba5_logo.svg',
  },
  '@id': 'https://hyperhuman.cc#Organization',
};

export const Head = function Head() {
  const thirdpartyEnabled = useMemo(() => {
    if (isBrowser()) {
      const isEmbeddable = embeddablePaths.includes(window.location.pathname);
      if (isEmbeddable) {
        return !isFrame();
      }
      return true;
    }
    return false;
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link
          id='favicon'
          rel='icon'
          href={`https://images.prismic.io/hyperhuman/f4b5a888-1aca-4611-8e04-2efbf2e4ca04_favicon.png?auto=compress,format`}
          type='image/x-icon'
        />
        <meta property='fb:app_id' content='529382751414370' />
        <script type='application/ld+json'>{JSON.stringify(schemaOrgJSONLD)}</script>
      </Helmet>
      {thirdpartyEnabled ? <ThirdPartyHelmet /> : <></>}
    </>
  );
};

const ThirdPartyHelmet = React.memo(function ThirdPartyHelmet() {
  return (
    <Helmet>
      <script async={true}>
        {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',150,
{'OPT-WMPR34D':true});`}
      </script>
      <script async={true}>
        {` (function(c,l,a,r,i,t,y){
c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "dx9tmkg90p");`}
      </script>
      <script async={true} src={`https://www.googleoptimize.com/optimize.js?id=OPT-WMPR34D`}></script>
      <script
        async={true}
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_WEBSITE_GA_ID}`}
      ></script>
      <script async={true}>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5ZKXB3H');
`}
      </script>

      <script async={true}>
        {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${process.env.GATSBY_WEBSITE_GA_ID}');`}
      </script>
      <script async src='https://www.googletagmanager.com/gtag/js?id=AW-437318515'></script>
      <script async={true}>
        {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'AW-437318515');`}
      </script>
      <script async={true}>
        {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '130546408847547');
fbq('track', 'PageView');`}
      </script>
      <script async={true}>
        {`
function initFreshChat() {
window.fcWidget.init({
token: "6ad0dd25-3487-41bd-9953-26f60d972724",
host: "https://wchat.eu.freshchat.com"
});
}
function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.eu.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
`}
      </script>

      <script async={true}>
        {`
var _tip = _tip || [];
(function(d,s,id){
var js, tjs = d.getElementsByTagName(s)[0];
if(d.getElementById(id)) { return; }
js = d.createElement(s); js.id = id;
js.async = true;
js.src = d.location.protocol + '//app.truconversion.com/ti-js/19142/9a979.js';
tjs.parentNode.insertBefore(js, tjs);
}(document, 'script', 'ti-js'));
`}
      </script>
      <script
        id='optinly_script'
        async={true}
        data-app_id='9c551540-b91e-4906-87ba-d921afdc7f0d'
        src='https://cdn.optinly.net/v1/optinly.js'
      />
      <script async={true}>
        {`
_linkedin_partner_id = "3656476"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
`}
      </script>
    </Helmet>
  );
});
