import React, { createContext, useState } from 'react';

type Theme = 'light' | 'dark';

interface IThemeContext {
  navigationTheme: Theme;
  setNavigationTheme?: (theme: Theme) => void;
}

const NavigationThemeContext = createContext<IThemeContext>({
  navigationTheme: 'light',
});

const NavigationThemeProvider: React.FC = ({ children }) => {
  const [navigationTheme, setNavTheme] = useState<Theme>('light');

  const setNavigationTheme = (theme: Theme) => {
    setNavTheme(theme);
  };

  return (
    <div>
      <NavigationThemeContext.Provider value={{ navigationTheme, setNavigationTheme }}>
        {children}
      </NavigationThemeContext.Provider>
    </div>
  );
};

export { NavigationThemeContext, NavigationThemeProvider };
