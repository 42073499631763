const theme = {
  colors: {
    primary: '#1291FF',
    white: '#fff',
    mystic: '#E2EBEB',
    mountainMeadow: '#11CC88',
    outerSpace: '#243636',
    nebula: '#CCDBDB',
    grannySmith: '#889E9E',
    nevada: '#5D7575',
    limedSpruce: '#3B5252',
    geyser: '#CFDDDD',
    rainForest: '#007060',
    sunsetOrange: '#FF5252',
  },
  maxWidth: '1000px',
  maxWidthText: '720px',
  breakpoints: {
    phone: '375px',
    tablet: '480px',
    smallLaptop: '769px',
    desktop: '1025px',
    largeDesktop: '1201px',
  },
};

export default theme;
