import { isBrowser, isFrame } from '@utils';
import React, { useMemo } from 'react';
import CookieConsent from 'react-cookie-consent';
import { embeddablePaths } from '@config/paths';

const Cookies: React.FC = () => {
  const show = useMemo(() => {
    if (isBrowser()) {
      const isEmbeddable = embeddablePaths.includes(window.location.pathname);
      if (isEmbeddable) {
        return !isFrame();
      }
      return true;
    }
    return false;
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept cookies'
      cookieName='cookieConsent'
      contentClasses='cookieContentClass'
      buttonWrapperClasses='cookieButtonClass'
      style={{
        background: '#243636',
        alignItems: 'center',
      }}
      buttonStyle={{
        backgroundColor: '#007060',
        borderRadius: '12px',
        height: '52px',
        padding: '0px 24px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: '700',
        textTransform: 'uppercase',
        lineHeight: '20.8px',
      }}
      expires={150}
    >
      <span
        style={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20.8px',
          letterSpacing: '-1%',
        }}
      >
        <div>
          This website uses cookies 🍪.
          <br />
          By continuing to navigate, you accept the use of cookies.
        </div>
      </span>
    </CookieConsent>
  );
};

export default Cookies;
