import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { logEvent as firebaseLogEvent, EventParams } from 'firebase/analytics';
import { isBrowser } from '../isBrowser';

const firebaseConfig = {
  apiKey: process.env.GATSBY_CLUB_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_CLUB_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_CLUB_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_CLUB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_CLUB_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_CLUB_FIREBASE_APP_ID,
};

let firebaseApp;
let analytics: Analytics;

if (isBrowser()) {
  firebaseApp = initializeApp(firebaseConfig, 'club');
  const app = firebaseApp;
  analytics = getAnalytics(app);
}

export const logClubEvent = (eventName: string, eventParams?: EventParams) => {
  if (isBrowser()) {
    firebaseLogEvent(analytics, eventName, eventParams);
  }
};
