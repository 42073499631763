exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-book-a-demo-tsx": () => import("./../../../src/pages/book-a-demo.tsx" /* webpackChunkName: "component---src-pages-book-a-demo-tsx" */),
  "component---src-pages-book-success-tsx": () => import("./../../../src/pages/book-success.tsx" /* webpackChunkName: "component---src-pages-book-success-tsx" */),
  "component---src-pages-campaign-tsx": () => import("./../../../src/pages/campaign.tsx" /* webpackChunkName: "component---src-pages-campaign-tsx" */),
  "component---src-pages-club-tsx": () => import("./../../../src/pages/club.tsx" /* webpackChunkName: "component---src-pages-club-tsx" */),
  "component---src-pages-content-api-tsx": () => import("./../../../src/pages/content-api.tsx" /* webpackChunkName: "component---src-pages-content-api-tsx" */),
  "component---src-pages-exercise-tsx": () => import("./../../../src/pages/exercise.tsx" /* webpackChunkName: "component---src-pages-exercise-tsx" */),
  "component---src-pages-exercises-tsx": () => import("./../../../src/pages/exercises.tsx" /* webpackChunkName: "component---src-pages-exercises-tsx" */),
  "component---src-pages-featured-workouts-tsx": () => import("./../../../src/pages/featured-workouts.tsx" /* webpackChunkName: "component---src-pages-featured-workouts-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-group-tsx": () => import("./../../../src/pages/group.tsx" /* webpackChunkName: "component---src-pages-group-tsx" */),
  "component---src-pages-groups-tsx": () => import("./../../../src/pages/groups.tsx" /* webpackChunkName: "component---src-pages-groups-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-plan-tsx": () => import("./../../../src/pages/plan.tsx" /* webpackChunkName: "component---src-pages-plan-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-scan-tsx": () => import("./../../../src/pages/scan.tsx" /* webpackChunkName: "component---src-pages-scan-tsx" */),
  "component---src-pages-wix-tsx": () => import("./../../../src/pages/wix.tsx" /* webpackChunkName: "component---src-pages-wix-tsx" */),
  "component---src-pages-workouts-tsx": () => import("./../../../src/pages/workouts.tsx" /* webpackChunkName: "component---src-pages-workouts-tsx" */)
}

