import { css } from '@emotion/react';
import theme from './theme';

const { breakpoints } = theme;

const swiper = css`
  .app-carousel .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .-carousel .swiper-wrapper {
    align-items: flex-start;
  }
  .news-carousel .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .news-carousel .swiper-wrapper {
    align-items: flex-start;
  }
  .testimonial-carousel {
    padding-bottom: 60px !important;
  }

  .swiper {
    position: relative;
  }

  .business-partner-carousel .swiper-pagination-bullet {
    position: relative;
  }

  .business-partner-carousel .swiper-horizontal > .swiper-pagination-bullets,
  .business-partner-carousel .swiper-pagination-bullets.swiper-pagination-horizontal,
  .business-partner-carousel .swiper-pagination-custom,
  .business-partner-carousel .swiper-pagination-fraction {
    position: absolute;
    top: 280px;
  }

  .testimonial-carousel .swiper-pagination-bullet,
  .business-partner-carousel .swiper-pagination-bullet {
    background: #889e9e;
  }
  .testimonial-carousel .swiper-pagination-bullet-active,
  .business-partner-carousel .swiper-pagination-bullet-active {
    background: #11cc88;
  }
  .swiper-wrapper {
    align-items: flex-start;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    .swiper-wrapper {
      align-items: center;
    }
    .business-partner-carousel .swiper-pagination-bullet {
      position: initial;
    }
    .business-partner-carousel .swiper-horizontal > .swiper-pagination-bullets,
    .business-partner-carousel .swiper-pagination-bullets.swiper-pagination-horizontal,
    .business-partner-carousel .swiper-pagination-custom,
    .business-partner-carousel .swiper-pagination-fraction {
      position: none;
      top: unset;
      bottom: 10px;
    }
    .splide__slide.splide__slide--col {
      align-items: center !important;
      display: flex;
    }
    .splide__slide__row > li:first-child {
      margin-right: 24px !important;
      margin-top: 100px !important;
    }
  }
`;

export default swiper;
