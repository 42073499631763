export const websiteConfig = {
  pathPrefix: '/',
  title: 'Hyperhuman',
  titleAlt: 'Hyperhuman',
  description: 'Hyperhuman',
  headline: 'Hyperhuman',
  url: 'https://hyperhuman.cc',
  logo: '/logos/logo-1024.png',
  ogLanguage: 'en_US',

  favicon: 'src/favicon.png',
  shortName: 'Hyperhuman',
  author: 'Hyperhuman',
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '',
  facebook: '',

  skipNavId: 'reach-skip-nav',
};
