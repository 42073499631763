import * as React from 'react';

import { IconProps } from '@appTypes';
import { SUBMARINE } from '@constants';

function ChevronBottomIcon(props: IconProps) {
  const { stroke = SUBMARINE, strokeWidth = 2 } = props;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6 9L12 15L18 9'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ChevronBottomIcon;
