import styled from '@emotion/styled';
import { theme } from '@styles';

const { breakpoints } = theme;

const HeaderTitleWrapper = styled.div`
  margin-top: 34px;
  margin-bottom: 54px;
  padding: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
    margin-bottom: 54px;
    padding: 0 24px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
    margin-bottom: 100px;
  }
`;

export default HeaderTitleWrapper;
