import styled from '@emotion/styled';
import theme from '../theme';

const { breakpoints } = theme;

const SectionWrapper = styled.div`
  margin: 60px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 120px 0px;
  }
`;

export default SectionWrapper;
