// Backgrounds
export const MERCURY = '#E5E5E5';
// Primary
export const MOUNTAIN_MEADOW = '#11CC88';
// Blacks
export const OUTER_SPACE = '#243636';
export const LIMED_SPRUCE = '#3B5252';
export const NEVADA = '#5D7575';
export const GRANNY_SMITH = '#889E9E';
export const SUBMARINE = '#B8CCCC';
export const MYSTIC = '#E2EBEB';
export const WHITE = '#FFF';
export const GEYSER = '#D2DFDF';
// Secondary #1
export const RAIN_FOREST = '#007060';
// Secondary #2
export const CANDLELIGHT = '#FED624';
// Tertiary #1
export const SUNSET_ORANGE = '#FF5252';
// Tertiary #2
export const CORNFLOWER_LILIAC = '#FFB3A2';
// Tertiary #3
export const WINDSOR = '#560A71';
// Tertiary #4
export const TURQUOISE = '#46DED5';
// Tertiary #5
export const DODGER_BLUE = '#1291FF';
export const BLACK_HAZE = '#F4F6F6';
